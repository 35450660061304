.rui-social-links {
    padding: 0;
    margin: -10px -8px 0;
    font-size: 0;
    list-style-type: none;

    &::after {
        content: "";
        display: table;
        clear: both;
    }

    > li {
        display: inline-block;
        padding: 10px 5px 0;

        > a,
        > button,
        > span,
        > div {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            min-height: 36px;
            padding: 9.5px 15px;
            font-size: 11px;
            font-weight: 700;
            text-transform: uppercase;
            cursor: pointer;
            will-change: opacity;
            border: none;
            border-radius: .25rem;
            transition: background-color .15s ease-in-out;

            > svg {
                margin-right: 10px;
                font-size: 1rem;
            }

            &,
            &:focus,
            &:active {
                color: #fff;
            }

            &:hover,
            &.hover {
                text-decoration: none;
            }
        }
    }

    @each $name, $color in $colors_social {
        > li > .rui-social-#{$name} {
            color: #fff;
            background-color: $color;

            &:hover,
            &.hover {
                color: #fff;
                background-color: darken($color, 5%);
            }
        }
    }
}

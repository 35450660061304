.rui-kanban {
    padding-bottom: 10px;
}
.rui-kanban-col {
    display: flex;
    flex-direction: column;
    min-width: 360px;
    height: 100%;
    max-height: calc(100vh - 200px);
    padding: 30px 26px 30px 30px;
    overflow-y: scroll;
    background-color: $color_grey_1;
    border-radius: .25rem;

    &::-webkit-scrollbar-track {
        background-color: $color_grey_1;
        border-radius: 4px;
    }
    &::-webkit-scrollbar {
        width: 4px;
        background-color: $color_grey_1;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $color_grey_3;
        border-radius: 4px;
    }

    .rui-sortable {
        height: 100%;
        margin-bottom: 0;
    }
}
.rui-kanban-item {
    cursor: pointer;
    border: 1px solid $color_grey_2;
    border-radius: .25rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out;

    + .rui-kanban-item {
        margin-top: 10px;
    }
    > .rui-task {
        padding-right: 20px;
        padding-left: 20px;
        background-color: #fff;
        border-radius: .25rem;
    }
    &:hover,
    &.hover {
        background-color: #fff;
        border-color: $color_grey_3;

        > .rui-task {
            background-color: #fff;
        }
    }
    &.sortable-chosen {
        background-color: #fff;
        border-color: $color_grey_4;
    }
}

@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

@import './assets/golos-black.css';
@import './assets/golos-regular.css';

//@primary-color: #3269ff;
@layout-body-background: #f8f9fa;
@height-lg: 44px;
@zindex-modal: 1005;
@zindex-modal-mask: 1005;

@primary-color: #13509F;
@link-color: @primary-color;
@processing-color: @primary-color;
@text-selection-bg: @primary-color;
@error-color: #D94D30;
@highlight-color: @error-color;
@success-color: #4DA45D;
@layout-header-background: #2D2D2D;
@text-color: rgba(0, 0, 0, 0.65);
@heading-color: #323232;

@border-radius-base: 8px;
@border-radius-sm: @border-radius-base;

@content-font: 16px;

.ant {
  &-message .anticon svg {
    display: block;
  }

  &-select-item-option {
    &-grouped {
      padding-left: 12px;
    }

    &-content .anticon {
      vertical-align: 1px;
    }
  }
}

.spinner-border {
    border-width: .15em;
}

.btn > .spinner-border {
    align-self: center;
    width: 1em;
    height: 1em;
    margin-left: 1em;
}

.alert {
    padding: 19px 25px;
    margin: 0;
    color: $color_secondary;
    background-color: $color_grey_1;
    border: 0;
    border-left: 2px solid;

    + .alert {
        margin-top: 10px;
    }

    hr {
        border-top-color: $color_separator;
    }
    .alert-link {
        color: $color_text_1;
    }
}
@each $name, $color in $colors_alerts {
    .alert-#{$name} {
        border-color: $color;
    }
}

.alert-link {
    font-weight: 600;
}
.alert-content {
    padding: 30px;

    p + hr {
        margin-top: 25px;
    }
    hr {
        margin: 30px 0;
    }
}
.alert-dismissible .close {
    display: inline-flex;
    align-items: center;
    padding: 21.5px 22px;
    transition: color .15s ease-in-out;

    &:hover,
    &.hover {
        color: $color_text_1;
    }
}
